import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { ChevronsUpDown, X, XIcon } from 'lucide-react';
import * as React from 'react';
import { Control, ControllerRenderProps } from 'react-hook-form';
import { useRemixFormContext } from 'remix-hook-form';

import { Badge } from './badge';
import { Button } from './button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Separator } from './separator';
import { cn } from '../../utils';

interface ComboboxMultipleSelectProps {
  initialSelectedValues: string[];
  title?: string;
  options: {
    label: string;
    value: string;
  }[];
}
export type OptionType = Record<'value' | 'label', string>;

export interface MultiSelectProps {
  options: Record<'value' | 'label', string>[];
  selected: Record<'value' | 'label', string>[];
  onChange: React.Dispatch<
    React.SetStateAction<Record<'value' | 'label', string>[]>
  >;
  popoverClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  truncateEntries?: boolean;
  showClearAll?: boolean;
  showSelectAll?: boolean;
  className?: string;
  plusIcon?: boolean;
  isDisabled?: boolean;
}

export const FormMultiSelect = React.forwardRef<
  HTMLButtonElement,
  MultiSelectProps
>(
  (
    {
      options,
      selected,
      onChange,
      inputClassName,
      popoverClassName,
      truncateEntries,
      className,
      plusIcon,
      isDisabled,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        // close on escape
        if (e.key === 'Escape') {
          setOpen(false);
        }
      };
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [onChange, selected]);

    const handleUnselect = (item: Record<'value' | 'label', string>) => {
      onChange(selected.filter((i) => i.value !== item.value));
    };

    const handleClearAll = () => {
      onChange([]);
    };
    const handleSelectAll = () => {
      onChange([...options]);
    };
    return (
      <Popover modal={false} open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild className={inputClassName}>
          <Button
            ref={ref}
            variant="outline"
            size="sm"
            role="combobox"
            disabled={isDisabled}
            aria-expanded={open}
            className={cn('h-9 border-dashed p-2 bg-white', className)}
            onClick={() => setOpen(!open)}
          >
            {plusIcon ? <PlusCircledIcon className="mr-2 h-4 w-4" /> : ''}
            <div className="flex flex-wrap items-center gap-1">
              {truncateEntries && selected.length > 2 && selected.length > 2
                ? `${selected.length} selected`
                : selected.map((item) => (
                    <Badge
                      variant="secondary"
                      key={item.value}
                      className="rounded-sm px-1 font-normal truncate h-5"
                    >
                      {item.label.substring(0, 8)}...
                      <button
                        className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleUnselect(item);
                          }
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        onClick={() => handleUnselect(item)}
                      >
                        <XIcon
                          size={'12'}
                          className="text-muted-foreground hover:text-foreground"
                        />
                      </button>
                    </Badge>
                  ))}
              {selected.length === 0 && (
                <span>{props.placeholder ?? 'Select ...'}</span>
              )}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0" align="start">
          <Command className={cn(popoverClassName)}>
            <CommandInput placeholder="Search ..." />
            <CommandEmpty>No item found.</CommandEmpty>
            {props.showSelectAll || props.showClearAll ? (
              <CommandGroup>
                {props.showSelectAll ? (
                  <CommandItem
                    onSelect={() => {
                      handleSelectAll();
                      setOpen(true);
                    }}
                  >
                    Select all
                  </CommandItem>
                ) : (
                  ''
                )}
                {props.showClearAll ? (
                  <CommandItem
                    onSelect={() => {
                      handleClearAll();
                      setOpen(true);
                    }}
                  >
                    Clear all
                  </CommandItem>
                ) : (
                  ''
                )}
              </CommandGroup>
            ) : (
              ''
            )}
            <CommandGroup className="max-h-64 overflow-auto">
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  onSelect={() => {
                    onChange(
                      selected.some((item) => item.value === option.value)
                        ? selected.filter((item) => item.value !== option.value)
                        : [...selected, option]
                    );
                    setOpen(true);
                  }}
                >
                  <div
                    className={cn(
                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                      selected.some((item) => item.value === option.value)
                        ? 'bg-primary text-primary-foreground'
                        : 'opacity-50 [&_svg]:invisible'
                    )}
                  >
                    <CheckIcon className={cn('h-4 w-4')} />
                  </div>
                  <span>{option.label}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

export function ComboboxMultipleSelect({
  initialSelectedValues,
  title,
  options,
}: ComboboxMultipleSelectProps) {
  const [selectedValues, setSelectedValues] = React.useState(
    new Set(initialSelectedValues)
  );
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-0.5 font-normal py-1"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.value}
                        className="rounded-sm px-1 font-normal truncate"
                      >
                        {option.label.substring(0, 5)}...
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        setSelectedValues((prev) => {
                          prev.delete(option.value);
                          return new Set(prev);
                        });
                      } else {
                        setSelectedValues(
                          (prev) => new Set(prev.add(option.value))
                        );
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => setSelectedValues(() => new Set())}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

// New component

interface ComboboxWithMultipleSelectProps {
  initialSelectedValues: string[];
  title?: string;
  options: Record<'value' | 'label', string>[];
  selected: Record<'value' | 'label', string>[];
  onChange: React.Dispatch<
    React.SetStateAction<Record<'value' | 'label', string>[]>
  >;
  onApply?: () => void;
  children?: React.ReactNode;
  isDirty?: boolean;
  className?: string;
  plusIcon?: boolean;
  arrowsIcon?: boolean;
  isDisabled?: boolean;
  showSelectAll?: boolean;
}

export const ComboboxWithMultipleSelect = React.forwardRef<
  HTMLButtonElement,
  ComboboxWithMultipleSelectProps
>(
  (
    {
      selected,
      title,
      options,
      onChange,
      onApply,
      isDirty,
      className,
      plusIcon,
      arrowsIcon,
      isDisabled,
      showSelectAll = false,
    },
    ref
  ) => {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            disabled={isDisabled}
            role="combobox"
            className={cn('h-8 border-dashed flex justify-start', className)}
          >
            {plusIcon ? <PlusCircledIcon className="mr-2 h-4 w-4" /> : ''}
            <span className={cn({ 'flex-1': selected?.length === 0 })}>
              {title}
            </span>
            {selected?.length > 0 && (
              <div className="flex items-center flex-1 space-x-1">
                <Separator orientation="vertical" className="mx-2 h-4" />
                {selected.length <= 5
                  ? options
                      .filter((option) =>
                        selected.some((item) => item.value === option.value)
                      )
                      .map((option) => (
                        <span className="relative" key={option.value}>
                          <Badge
                            variant="secondary"
                            className="rounded-sm p-1 font-light truncate"
                          >
                            {option.label.length > 10
                              ? option.label.substring(0, 10) + '...'
                              : option.label}
                          </Badge>
                          {/* <span
                            className="absolute -right-1 -top-1 rounded-full bg-black text-white flex items-center justify-center"
                            onClick={(e) => {
                              onChange(
                                selected.some(
                                  (item) => item.value === option.value
                                )
                                  ? selected.filter(
                                      (item) => item.value !== option.value
                                    )
                                  : [...selected, option]
                              );
                            }}
                          >
                            <X size={12} />
                          </span> */}
                        </span>
                      ))
                  : selected.length + ' selected'}
                {/* <Badge
                  variant="secondary"
                  className="rounded-sm p-1 font-normal lg:hidden"
                >
                  {selected.map((item) => item.label).join(', ')}
                </Badge> */}
                {/* <div className=" space-x-1 lg:flex">
                  {selected.length <= 5 &&
                    options
                      .filter((option) =>
                        selected.some((item) => item.value === option.value)
                      )
                      .map((option) => (
                        <Badge
                          variant="secondary"
                          key={option.value}
                          className="rounded-sm px-1 font-normal truncate h-5"
                        >
                          {option.label.substring(0, 5)}...
                        </Badge>
                      ))}
                </div> */}
              </div>
            )}
            {arrowsIcon ? (
              <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 ml-2" />
            ) : (
              ''
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="h-auto  p-0" align="start">
          <Command>
            <CommandInput placeholder={title} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options?.map((option) => {
                  const isSelected = selected.some(
                    (item) => item.value === option.value
                  );
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={(e) => {
                        onChange(
                          selected.some((item) => item.value === option.value)
                            ? selected.filter(
                                (item) => item.value !== option.value
                              )
                            : [...selected, option]
                        );
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isSelected
                            ? 'bg-primary text-primary-foreground'
                            : 'opacity-50 [&_svg]:invisible'
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <span>{option.label}</span>
                    </CommandItem>
                  );
                })}
                {options.length === 0 && (
                  <CommandItem
                    className="justify-center text-center text-red-500"
                    onSelect={() => {
                      onChange([]);
                    }}
                  >
                    No results found.
                  </CommandItem>
                )}
              </CommandGroup>
            </CommandList>
            {selected.length > 0 && (
              <>
                <CommandGroup>
                  {/* show if showSelectAll is true */}
                  {showSelectAll && (
                    <CommandItem
                      className="justify-center text-center text-brand-teal"
                      onSelect={() => {
                        onChange([...options]);
                      }}
                    >
                      Select all
                    </CommandItem>
                  )}
                  {selected.length > 0 && (
                    <CommandItem
                      onSelect={() => {
                        onChange([]);
                      }}
                      className="justify-center text-center text-red-500"
                    >
                      Clear filters
                    </CommandItem>
                  )}
                </CommandGroup>
              </>
            )}

            {isDirty && onApply != null && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem className="justify-center text-center text-blue-600 cursor-pointer">
                    <span onClick={onApply}>Apply</span>
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

interface ReportOptionType {
  label: string;
  value: string;
}

interface ComboboxWithMultipleSelectForReportsProps {
  initialSelectedValues: string[];
  title?: string;
  options: ReportOptionType[];
  selected: string[];
  onChange: React.Dispatch<React.SetStateAction<ReportOptionType[]>>;
  onApply?: () => void;
  children?: React.ReactNode;
  isDirty?: boolean;
  className?: string;
  plusIcon?: boolean;
  arrowsIcon?: boolean;
  isDisabled?: boolean;
  name: string;
}

export const ComboboxWithMultipleSelectForReports = React.forwardRef<
  HTMLButtonElement,
  ComboboxWithMultipleSelectForReportsProps
>(
  (
    {
      selected,
      title,
      options,
      onChange,
      onApply,
      isDirty,
      className,
      plusIcon,
      arrowsIcon,
      isDisabled,
      name,
    },
    ref
  ) => {
    const { setValue, register } = useRemixFormContext();
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            disabled={isDisabled}
            role="combobox"
            className={cn('h-8 border-dashed flex justify-start', className)}
          >
            {plusIcon ? <PlusCircledIcon className="mr-2 h-4 w-4" /> : ''}
            <span className={cn({ 'flex-1': selected?.length === 0 })}>
              {title}
            </span>
            {selected?.length > 0 && (
              <div className="flex items-center flex-1">
                <Separator orientation="vertical" className="mx-2 h-4" />
                {selected.length <= 5
                  ? options
                      .filter((option) =>
                        selected.some((item) => item === option.value)
                      )
                      .map((option) => (
                        <Badge
                          variant="secondary"
                          key={option.value}
                          className="rounded-sm p-1 font-normal mr-1 truncate max-w-[150px] block whitespace-nowrap"
                        >
                          {selected.length > 3
                            ? option.label.substring(0, 5) + '...'
                            : option.label}
                        </Badge>
                      ))
                  : selected.length + ' selected'}
              </div>
            )}
            {arrowsIcon ? (
              <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50 ml-2" />
            ) : (
              ''
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="h-auto  p-0" align="start">
          <Command>
            <CommandInput placeholder={title} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                <input
                  type="hidden"
                  {...register(name)}
                  defaultValue={selected}
                />
                {options?.map((option) => {
                  const isSelected = selected.some(
                    (item) => item === option.value
                  );
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => {
                        const newlySelected = selected.some(
                          (item) => item === option.value
                        )
                          ? selected.filter((item) => item !== option.value)
                          : [...selected, option.value];
                        setValue(name, newlySelected);
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isSelected
                            ? 'bg-primary text-primary-foreground'
                            : 'opacity-50 [&_svg]:invisible'
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <span>{option.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
            {selected.length > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => {
                      onChange([]);
                    }}
                    className="justify-center text-center text-red-500"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
            {isDirty && onApply != null && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem className="justify-center text-center text-blue-600 cursor-pointer">
                    <span onClick={onApply}>Apply</span>
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
